import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77709576"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle-switch" }
const _hoisted_2 = {
  class: "toggle-switch-rail",
  ref: "toggleSwitchRailRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("div", {
        class: "toggle-switch-body",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onClicked()))
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["toggle-switch-option", {active: $props.activeOptionIndex === index}]),
              key: item.id
            }, _toDisplayString(item), 3))
          }), 128))
        ], 512),
        _createElementVNode("div", {
          class: "toggle-switch-lever",
          style: _normalizeStyle($setup.leverStyle)
        }, null, 4)
      ])
    ], true)
  ]))
}