import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "key-field-confirmation-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "key-field-confirmation-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Key Parameter Change",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
    onMaskClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, "You are about to change " + _toDisplayString($props.title) + " PN - Rev.", 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["NButton"], {
          class: "small-button confirm-button n-button-confirm",
          type: "info",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onConfirm()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Confirm")
          ]),
          _: 1
        }),
        _createVNode($setup["NButton"], {
          class: "cancel-button medium-button n-button-cancel",
          type: "tertiary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}